<template>
  <Modal v-model="distributionListFormatModal" :title="$t('key1005529')" width="500" class-name="distributionListFormatStyle"
    :transfer="false" :mask-closable="false" @on-visible-change="distributionListFormatChange">
    <Form ref="distributionListFormatForm" :model="distributionListFormatForm" :label-width="100" @submit.native.prevent>
      <Row style="width: 90%;">
        <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
          <Form-item :label="$t('key1005530')" prop="type" class="radio_styles">
            <RadioGroup v-model="distributionListFormatForm.type">
              <Radio :label="1">{{ $t('key1005531') }}</Radio>
              <Radio :label="2">{{ $t('key1005532') }}</Radio>
            </RadioGroup>
          </Form-item>
        </Col>
      </Row>
    </Form>
    <template #footer>
      <Button @click="distributionListFormatModal = false">{{ $t('key1000097') }}</Button>
      <Button type="primary" @click="distributionListFormatBtn">{{ $t('key1000096') }}</Button>
    </template>
  </Modal>
</template>

<script>
import { alias689d3582fa8c4a7891f9d6ea8bcd03a9 } from '@/customFolder/customVueAlias.js';

import Mixin from "@/components/mixin/common_mixin";
import api from '@/api/';
import {downloadTemplateFile} from "@/utils/common";

export default {
  name: "distributionListFormatModal",
  mixins: [Mixin],
  data() {
    return {
      distributionListFormatModal: false,
      distributionListFormatForm: {
        type: 1
      },
      pickingList: [],
      pathType: null,
    }
  },
  methods: {
    // 初始化数据
    initDistributionListFormat(data, type) {
      this.pickingList = [];
      data.map((item) => {
        this.pickingList.push(item.packageId)
      });
      this.pathType = type;
      this.distributionListFormatModal = true;
    },
    // 确定按钮
    distributionListFormatBtn() {
      // 纸质清单
      if (this.distributionListFormatForm.type === 1) {
        const {href} = this.$router.resolve({
          path: '/printPaperList',
          query: {
            data: this.pickingList.join(','),
            type: this.pathType
          }
        });
        window.open(href, '_blank');
      }
      // 表格文件
      else {
        let query = {
          packageIds: this.pickingList,
          printType: 2
        };
        let url = '';
        if (this.pathType === 'supplierOrderList') {
          url = api.post_packageInfo_printPrepareGoods;
        } else if (this.pathType === 'stockList') {
          url = api.post_packageInfo_printPrepareGoodsStock;
        }
        this.axios.post(url, query, {loading: true, loadingText: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000212')}).then(res => {
          if (res.data.code === 0) {
            let data = res.data.datas;
            if (data) {
              this.distributionListFormatModal = false;
              this.$emit('updateSelect', true);
              let url = data.excelPath;
              downloadTemplateFile(url);
            }
          }
        });
      }
    },
    // 监听弹窗
    distributionListFormatChange(value) {
      if (!value) {
        this.pickingList = [];
        this.$refs['distributionListFormatForm'].resetFields();
      }
    }
  }
}
</script>

<style scoped>

</style>