import { render, staticRenderFns } from "./supplierOrderList.vue?vue&type=template&id=63203388&scoped=true&"
import script from "./supplierOrderList.vue?vue&type=script&lang=js&"
export * from "./supplierOrderList.vue?vue&type=script&lang=js&"
import style0 from "./supplierOrderList.vue?vue&type=style&index=0&id=63203388&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63203388",
  null
  
)

export default component.exports