<template>
  <Modal v-model="scanOrderChangeModal" :title="$t('key1005533')" width="600" :transfer="false"
    :mask-closable="false" @on-visible-change="scanOrderChange">
    <div class="content_box">
      <div class="felx align-items-center mb20">
        <span>{{ $t('key1005535') }}</span>
        <Input :element-id="'skuValueDom'" :placeholder="$t('key1005534')" v-model.trim="skuValue"
          clearable style="width: 250px;" @on-enter="scanSkuEnter"></Input>
      </div>
      <template v-if="Object.keys(details).length>0">
        <div class="flex mb20">
          <div class="mr20">
            <Tooltip placement="right" theme="light" :transfer="true" transfer-class-name="set_bigimg_style">
              <img class="img_styles" :src="setImgPath(details.primaryImage)" @error="setErrorImg($event)" alt=""/>
              <template #content>
                <img :ref="'thumbnailImg1'" @load="handlerOnloadImg('thumbnailImg1')"
                  :src="setImgPath(details.primaryImage)" @error="setErrorImg($event)" alt=""/>
              </template>
            </Tooltip>
          </div>
          <div>
            <h2 class="title mb5 themeColor" @click="toProductDetails">{{ details.cnName }}</h2>
            <p class="mb5 color-green">{{ $t('key1000206') + details.spec }}</p>
            <p class="mb5">{{ 'SKU：' + details.sku }}</p>
            <p class="mb5">{{ `${configCharacters}` + 'SKU：' + details.ymsSku }}</p>
          </div>
        </div>
        <div class="felx align-items-center mb10">
          <span>{{ $t('key1005536') }}</span>
          <InputNumber :max="99" :min="1" v-model.trim="batchPrintNum"
            :formatter="value => `${parseInt(value)}`" style="width: 150px;"></InputNumber>
          <span class="cursor themeColor ml12" @click="batchPrintBtn">{{ $t('key1000858') }}</span>
        </div>
      </template>
    </div>
    <template #footer>
      <div style="text-align: right;">
        <Button type="primary" @click="scanOrderChangeModal = false">{{ $t('key1005537') }}</Button>
      </div>
    </template>
  </Modal>
</template>

<script>
import { alias689d3582fa8c4a7891f9d6ea8bcd03a9 } from '@/customFolder/customVueAlias.js';

import Mixin from "@/components/mixin/common_mixin";
import api from '@/api/';

export default {
  name: "scanOrderChangeModal",
  mixins: [Mixin],
  data() {
    return {
      scanOrderChangeModal: false,
      skuValue: '',
      details: {},
      batchPrintNum: null,
      scanOrderType: null,
    }
  },
  methods: {
    // 扫描sku
    scanSkuEnter() {
      let v = this;
      if (v.skuValue) {
        v.handleScanSku(1, v.skuValue, 1);
      } else {
        v.$Message.warning(alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005538'));
        return false;
      }
    },
    // 批量打印扫描sku
    batchPrintBtn() {
      if (this.batchPrintNum) {
        this.handleScanSku(this.batchPrintNum, this.details.ymsSku, 2);
      } else {
        this.$Message.warning(alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005539'));
        return false;
      }
    },
    // 处理扫描sku
    handleScanSku(quantity, sku, type) {
      let v = this;
      let query = {
        quantity: quantity,
        sku: sku,
        type: type // 打印类型 1 首次打印  2 批量打印
      };
      let url = '';
      if (this.scanOrderType === 'supplierOrderList') {
        url = api.post_packageInfo_appendYmsSkuExpressBill;
      } else if (this.scanOrderType === 'stockList') {
        url = api.post_packageInfo_appendYmsSkuExpressBillStcok;
      }
      v.axios.post(url, query, {loading: true, loadingText: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000212')}).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas;
          if (data) {
            v.skuValue = '';
            v.batchPrintNum = null;
            setTimeout(() => {
              v.getFocus('skuValueDom');
            }, 1200);
            let url = data.filenodeFullUrl;
            if (url) {
              v.commonPrint(url, '1');
            } else {
              v.$Message.warning(alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005540'));
            }
            if (type === 1) {
              (async () => {
                let newData = await v.getProductInfoByIds([data.productGoodsId], [],
                  false, true, false, api.post_productInfo_queryByProductGoodsIds, 'supplier');
                if (newData && newData.length > 0) {
                  newData.map((item) => {
                    let productGoodsList = item.productGoodsList || [];
                    if (productGoodsList.length > 0) {
                      productGoodsList.map((ele) => {
                        if (ele.productGoodsId === data.productGoodsId) {
                          let skuImgPath = ele.primaryImage;
                          let spuImgPath = item.primaryImage;
                          let imgPath = skuImgPath ? skuImgPath : spuImgPath;
                          let productGoodsSpecificationList = ele.productGoodsSpecifications || [];
                          let spec = productGoodsSpecificationList ?
                            productGoodsSpecificationList.map(i => i.name + '：' + i.value).join('、') : '';
                          v.details = {
                            cnName: ele.cnName || item.cnName,
                            primaryImage: imgPath,
                            spec: spec,
                            ymsSku: ele.ymsSku,
                            sku: ele.sku
                          }
                        }
                      })
                    }
                  })
                }
              })();
            }
          }
        }
      });
    },
    // 监听弹窗
    scanOrderChange(value) {
      let v = this;
      if (value) {
        v.$nextTick(() => {
          v.getFocus('skuValueDom');
        });
      } else {
        v.batchPrintNum = null;
        v.skuValue = '';
        v.details = {};
      }
    },
    // 跳转到商品详情
    toProductDetails() {
      const {href} = this.$router.resolve({
        path: '/productDetails',
        query: {
          type: 'look',
          productId: params.row.productId
        }
      });
      window.open(href, '_blank');
    }
  }
}
</script>

<style lang="less" scoped>
.content_box {
  min-height: 150px;

  .img_styles {
    width: auto;
    height: 100px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    max-width: 150px;
  }
}
</style>