<template>
  <div class="flex">
    <div style="margin-right: 5px;">
      <Select v-model="selectValue" transfer @on-change="changeInputValue" :style="selectStyle">
        <Option v-for="item in dropDownTimeList" :key="item.value" :value="item.value">{{ item.title }}</Option>
      </Select>
    </div>
    <Date-picker
      type="datetimerange"
      class="wid100P"
      @on-clear="resetTime"
      @on-change="getTime"
      :clearable="true"
      transfer
      :format="formatType"
      placement="bottom-end"
      :placeholder="$t('key1000268')"
      :value="timeList">
    </Date-picker>
  </div>
</template>

<script>
import { alias689d3582fa8c4a7891f9d6ea8bcd03a9 } from '@/customFolder/customVueAlias.js';

export default {
  name: "dropDownDateTime",
  props: {
    dropDownTimeList: { // 下拉选项筛选条件的基础数据
      type: Array,
      default: () => []
    },
    defaultSelectValue: { // 默认展示下拉选项值
      type: Number,
      default: 1
    },
    selectStyle: { // 下拉选项的样式
      type: Object,
      default: () => {
        return {
          width: '110px'
        };
      }
    },
    formatType: { // 时间格式
      type: String,
      default: 'yyyy-MM-dd HH:mm:ss'
    }
  },
  data() {
    return {
      selectValue: '',
      timeList: []
    }
  },
  methods: {
    // 切换选项
    changeInputValue(value) {
      this.$emit('changeInput', value);
    },
    // 选取订单创建时间
    getTime(value) {
      let v = this;
      if (value.length === 0) {
        v.$Message.error(alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000284'));
        v.resetTime();
      } else {
        if (!value[0]) {
          v.resetTime();
        } else {
          v.$emit('selectTime', {date: value, value: this.selectValue});
        }
      }
    },
    // 重置时间
    resetTime() {
      this.$emit('resetTime', this.selectValue)
    },
  },
  watch: {
    // 监听下拉选项的默认值
    defaultSelectValue: {
      handler(value) {
        let v = this;
        v.selectValue = value;
      },
      immediate: true,
      deep: true
    },
  }
}
</script>

<style scoped>

</style>